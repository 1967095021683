import {
    Alert,
    Button,
    Grid
}                              from "@mui/material";
import React, {useEffect}      from "react";
import {useGobesContext}       from "../context/GobesContextProvider";
import UpsertObjectModal       from "../modals/UpsertObjectModal";
import BronBoorOverview        from "./BronBoorOverview";
import CompanyDetailOverview   from "./CompanyDetailOverview";
import CompanyOverview         from "./CompanyOverview";
import ControlesOverview       from "./ControlesOverview";
import NotitiesOverview        from "./NotitiesOverview";
import SysteemGegevensOverview from "./SysteemGegevensOverview";

const MainPage = () =>
{

    const {
        activePage, setActivePage,
        subPage, setSubPage,
        alertVisible, alertText,
        setAlertVisible,
    } = useGobesContext()

    useEffect(() =>
    {
        setAlertVisible(false)
    }, [subPage, activePage]);

    function onClickHome()
    {
        setActivePage(0)
    }

    function onClickBack()
    {
        //Decrement it by one to go back a page, disspears when activepage is 0
        if (activePage > 0)
        {
            setActivePage((activePage: number) => activePage - 1)
        }
    }

    const TopButtons = <Grid container justifyContent="center">
        <Grid item>
            <Grid item>
                <Button variant={"contained"} className={"default-button"} onClick={onClickHome}>Home</Button>
                {activePage !== 0 && <Button variant={"contained"} className={"default-button"} onClick={onClickBack}>Terug</Button>}
                <Alert style={{
                    display: alertVisible
                             ? "flex"
                             : "none",
                }} severity="error" color={"warning"}>{alertText}</Alert>

            </Grid>
        </Grid>
    </Grid>

    const SelectionButtons = <Grid container className={"rm-container"} justifyContent={"flex-start"} flexDirection={"row"}>
        <Button onClick={() => setSubPage(0)} variant={subPage === 0
                                                       ? "outlined"
                                                       : "text"}>Systeemgegevens</Button>
        <Button onClick={() => setSubPage(1)} variant={subPage === 1
                                                       ? "outlined"
                                                       : "text"}>Controles</Button>
        <Button onClick={() => setSubPage(2)} variant={subPage === 2
                                                       ? "outlined"
                                                       : "text"}>Notities</Button>
    </Grid>

    return (<>
        <p>GOBES</p>
        <Grid container direction={"column"}>
            {TopButtons}

            <Grid className={"rm-container"}>

                {activePage === 0 && <CompanyOverview/>}
                {activePage === 1 &&  <CompanyDetailOverview/>}

                {activePage === 2 && SelectionButtons}
                {activePage === 2 && subPage === 0 && <SysteemGegevensOverview/>}
                {activePage === 2 && subPage === 1 && <ControlesOverview/>}
                {activePage === 2 && subPage === 2 && <NotitiesOverview/>}

                {activePage === 3 && subPage === 0 && <BronBoorOverview/>}

            </Grid>
            <UpsertObjectModal/>

        </Grid>

    </>)
}
export default MainPage