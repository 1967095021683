import {
    Button,
    Typography
}                        from "@mui/material";
import {
    useEffect,
    useRef
}                        from "react";
import {
    AddEnergySystemCommand,
    AddPermitCommand,
    EnergySystem,
    EnergySystemDetail,
    Permit,
    PermitDetail,
    UpdateEnergySystemCommand,
    UpdatePermitCommand
} from "../constants";
import {useGobesContext} from "../context/GobesContextProvider";
import {
    submitExistingPermit,
    submitNewPermit,
    submitObject
} from "../util";

type Props = {}
const PermitForm = ({ data}: any) =>
{
    const FormRef = useRef<any>()
    const {energyDetails, setModalVisible,DoRefresh,     setAlertText,formData,companyDetails,setRefreshResource,setAlertVisible} = useGobesContext()

    useEffect(() =>
    {
        if (FormRef.current !== undefined && energyDetails !== undefined)
        {
            FormRef.current.data = {
                ...formData,
                energySystemId: energyDetails.id

            }

        }
    }, []);

    async function HandleSubmit(event: any)
    {
        //In case of a status that isnt 200, we most likely have received an error message.
        //So we display this as alert in the Modal
        if (event.status === 200)
        {
            //If everything went okay we can close it
            setModalVisible(false)
            setAlertVisible(false)
        }
        else
        {
            let response = await event.json()
            setAlertVisible(true)
            setAlertText(response.messages.reduceRight((acum: any, current: any) => acum.concat(current)))
        }

    }

    function onClickSubmit()
    {
        let Data: any = {}
        // @ts-ignore
        FormRef.current.getData().then((event: any) => Data = event)
        // @ts-ignore
        FormRef.current.submitForm().then(() =>
            {
                if (formData === undefined)
                {
                    submitObject(Data, companyDetails?.id, AddPermitCommand).then(async (event: any) =>
                    {
                        HandleSubmit(event)
                    })
                    setRefreshResource(Permit)

                }
                else
                {
                    submitObject(Data, companyDetails?.id, UpdatePermitCommand).then(async (event: any) =>
                    {
                        HandleSubmit(event)
                    })
                    setRefreshResource(PermitDetail)

                }
            }
        )

    }

    return (<>
        <Typography id="modal-modal-title" variant="h6" component="h2">
            {formData === undefined
             ? "Vergunning toevoegen"
             : "Vergunning bewerken"}
        </Typography>
        {formData === undefined
            // @ts-ignore
         ? <ig-form ref={FormRef} form-id="AddPermit"/>
            // @ts-ignore
         : <ig-form ref={FormRef} form-id="UpdatePermit"/>}

        <Button variant="contained" onClick={onClickSubmit}>Submit</Button>
    </>)
}
export default PermitForm