import {
    Button,
    Grid,
    Typography
}                      from "@mui/material";
import {
    useEffect,
    useRef,
    useState
}                      from "react";
import {
    AddContact,
    AddWell,
    Contact,
    ContactDetail,
    DeactivateContactCommand,
    IGStyle,
    UpdateContact,
    Well,
    WellDetail
} from "../../constants";
import {useGobesContext} from "../../context/GobesContextProvider";
import {submitObject} from "../../util";

const WellBlock = () =>
{
    const {
        companyDetails,
        selectedCompanyRecord,
        setFormData,
        setModalVisible,
        setModalAction,setActivePage, setSubPage,setSelectedBronFilterRecord,
        refreshResource, setRefreshResource, setAlertVisible, setAlertText,selectedEnergyRecord,setBronDetails
    } = useGobesContext()

    const RMRef = useRef<any>()
    const RDRef = useRef<any>()
    const [detailStatus, setDetailStatus] = useState<any>(false)
    const [detailRecord, setDetailRecord] = useState<any>("")

    useEffect(() =>
    {
        RMRef.current?.addEventListener('recordSelected', (event: any) =>
        {
            console.log(event.detail.record)
            setFormData(event.detail.record)
            setDetailStatus(true)
            setDetailRecord(event.detail.recordId)
            setSelectedBronFilterRecord(`wellId =="` + event.detail.record?.id + "\"")
            setBronDetails(event.detail.record)
            setActivePage(3)
            setSubPage(0)
            //  setModalVisible(true)
            //  setModalAction(UpdateContact)
        })
    }, [])

    useEffect(() =>
    {
        switch (refreshResource)
        {
            case Well:
                setTimeout(() => RMRef.current?.refresh(), 1000)
                break
            case WellDetail:
                setTimeout(() => RDRef.current?.refresh(), 1000)
                break
        }
        setRefreshResource("")
    }, [refreshResource]);

    function ResetDetailStatus()
    {
        setRefreshResource(Contact)
        setDetailStatus(false)
    }

    function AddObject()
    {
        setFormData(RMRef.current?.data)

        setModalVisible(true)
        setModalAction(AddWell)
    }

    function ContactBewerken() {
        setFormData(RDRef.current?.data)

        setModalVisible(true)
        setModalAction(UpdateContact)
    }

    function ContactVerwijderen() {
        submitObject({id: RDRef.current?.data.id},  companyDetails?.id, DeactivateContactCommand).then(async (event: any) =>
        {
            HandleSubmit(event)
        })

        setDetailStatus(false)
        setRefreshResource(Well)
    }
    async function HandleSubmit(event: any)
    {
        //In case of a status that isnt 200, we most likely have received an error message.
        //So we display this as alert in the Modal
        if (event.status !== 200){
            const response = await event.json()
            setAlertVisible(true)
            setAlertText(response.messages.reduceRight((acum: any, current: any) => acum.concat(current)))
        }
    }

    return (<>
        <Grid container justifyContent="center" direction={"column"}>
            <Grid container className={"rm-container"} direction={"row"}>
                <Grid container>
                    <Typography variant="h4" marginRight={"auto"}>
                        Bronnen
                    </Typography>
                </Grid>

                <Grid item xs={8} container alignItems={"stretch"} className={"containerspacing"}>

                   {/*// @ts-ignore*/}
                        <ig-resource-master lang="nl-NL" ref={RMRef} style={IGStyle} class={"ig-columns"} resource-master-id="WellRM" dsl-filter={selectedEnergyRecord}/>


                </Grid>

                <Grid container item className={"button-container"} xs={3} marginLeft={1} justifyContent={"flex-start"}>

                    <Grid container item xs={4}>
                        <Grid container direction="column" item>
                            <Button variant={"contained"} onClick={AddObject}>Bron Toevoegen</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    </>)
}

export default WellBlock