import {
    AddBoorprofiel,
    AddCadastralInfo,
    AddContact,
    AddControle,
    AddEnergySystem,
    AddNote,
    AddPermit,
    AddWell,
    AuthToken,
    baseUrl,
    CompanyUrl,
    CompanyUrlNew,
    RegisterCompanyCommand,
    UpdateBoorprofiel,
    UpdateCadastralInfo,
    UpdateContact,
    UpdateControle,
    UpdateEnergySystem,
    UpdateEnergySystemStatus,
    UpdateEnergySystemStatusToezicht,
    UpdateNote,
    UpdatePermit,
    UpdateWell
} from "./constants";


//<editor-fold desc="Retrieval">
export function getCompanyRecordSystem(Identifier: any)
{
    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        }
    };

    return fetch(baseUrl + CompanyUrl + Identifier, requestOptions).then()

}
//</editor-fold>

//<editor-fold desc="Update">

export function submitObject(details: any, AggregateId : string, Command: string)
{
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(details)
    };
    return fetch(baseUrl + CompanyUrl + AggregateId + Command, requestOptions)
        .then(response => response)
}

//</editor-fold>


//<editor-fold desc="Company">

export function submitNewCompany(companyDetails: any)
{
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(companyDetails)
    };
    return fetch(baseUrl + CompanyUrlNew + RegisterCompanyCommand, requestOptions)
        .then(response => response)
}
//
// export function submitExistingCompany(companyDetails: any)
// {
//     const requestOptions = {
//         method: 'POST',
//         headers: {
//             Authorization: 'Bearer ' + AuthToken
//         },
//         body: JSON.stringify(companyDetails)
//     };
//     return fetch(baseUrl + CompanyUrl + "/" + companyDetails.id  + UpdateCompany, requestOptions)
//         .then(response => response)
// }
//</editor-fold>

//<editor-fold desc="Energy System">

export function submitNewEnergySystem(energyDetails: any, AggregateId : any)
{
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(energyDetails)
    };
    return fetch(baseUrl + CompanyUrl + "/" + AggregateId  + AddEnergySystem, requestOptions)
        .then(response => response)
}

export function submitExistingEnergySystem(energyDetails: any, AggregateId : any)
{
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(energyDetails)
    };
    return fetch(baseUrl + CompanyUrl + "/" + AggregateId  + UpdateEnergySystem, requestOptions)
        .then(response => response)
}

export function submitExistingEnergySystemStatus(energyDetails: any, AggregateId : any)
{
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(energyDetails)
    };
    return fetch(baseUrl + CompanyUrl + "/" + AggregateId  + UpdateEnergySystemStatus, requestOptions)
        .then(response => response)
}

export function submitExistingEnergySystemStatusToezicht(energyDetails: any, AggregateId : any)
{
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(energyDetails)
    };
    return fetch(baseUrl + CompanyUrl + "/" + AggregateId  + UpdateEnergySystemStatusToezicht, requestOptions)
        .then(response => response)
}

//</editor-fold>

//<editor-fold desc="Cadastral">

export function submitNewCadastralDetail(cadastralDetails : any, AggregateId : any) {
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(cadastralDetails)
    };
    return fetch(baseUrl + CompanyUrl + "/" + AggregateId  + AddCadastralInfo, requestOptions)
        .then(response => response)
}

export function submitExistingCadastralDetail(cadastralDetails : any, AggregateId : any) {
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(cadastralDetails)
    };
    return fetch(baseUrl + CompanyUrl + "/" + AggregateId  + UpdateCadastralInfo, requestOptions)
        .then(response => response)
}

//</editor-fold>

//<editor-fold desc="Bron">

export function submitNewBron(bronDetails : any, AggregateId : any) {
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(bronDetails)
    };
    return fetch(baseUrl + CompanyUrl + "/" + AggregateId  + AddWell, requestOptions)
        .then(response => response)
}

export function submitExistingBron(bronDetails : any, AggregateId : any) {
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(bronDetails)
    };
    return fetch(baseUrl + CompanyUrl + "/" + AggregateId  + UpdateWell, requestOptions)
        .then(response => response)
}

//</editor-fold>

//<editor-fold desc="BoorProfiel">

export function submitNewBoorprofiel(boorDetails : any, AggregateId : any) {
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(boorDetails)
    };
    return fetch(baseUrl + CompanyUrl + "/" + AggregateId  + AddBoorprofiel, requestOptions)
        .then(response => response)
}

export function submitExistingBoorprofiel(boorDetails : any, AggregateId : any) {
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(boorDetails)
    };
    return fetch(baseUrl + CompanyUrl + "/" + AggregateId  + UpdateBoorprofiel, requestOptions)
        .then(response => response)
}

//</editor-fold>
//<editor-fold desc="Permit">

export function submitNewPermit(permitDetails : any, AggregateId : any) {
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(permitDetails)
    };
    return fetch(baseUrl + CompanyUrl + "/" + AggregateId  + AddPermit  , requestOptions)
        .then(response => response)
}

export function submitExistingPermit(permitDetails : any, AggregateId : any) {
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(permitDetails)
    };
    return fetch(baseUrl + CompanyUrl + "/" + AggregateId  + UpdatePermit  , requestOptions)
        .then(response => response)
}

//</editor-fold>

//<editor-fold desc="Contact">

export function submitExistingContact(data : any, AggregateId : any) {
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(data)
    };
    return fetch(baseUrl + CompanyUrl + "/" + AggregateId  + UpdateContact, requestOptions)
        .then(response => response)
}

export function submitNewContact(data : any, AggregateId : any) {
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(data)
    };
    return fetch(baseUrl + CompanyUrl + "/" + AggregateId  + AddContact, requestOptions)
        .then(response => response)
}

//</editor-fold>


//<editor-fold desc="Controle">

export function submitNewControle(cadastralDetails : any, AggregateId : any) {
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(cadastralDetails)
    };
    return fetch(baseUrl + CompanyUrl + "/" + AggregateId  + AddControle, requestOptions)
        .then(response => response)
}
export function submitExistingControle(cadastralDetails : any, AggregateId : any) {
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(cadastralDetails)
    };
    return fetch(baseUrl + CompanyUrl + "/" + AggregateId  + UpdateControle, requestOptions)
        .then(response => response)
}

//</editor-fold>




//<editor-fold desc="Note">

export function submitNewNote(permitDetails : any, AggregateId : any) {
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(permitDetails)
    };
    return fetch(baseUrl + CompanyUrl + "/" + AggregateId  + AddNote  , requestOptions)
        .then(response => response)
}

export function submitExistingNote(permitDetails : any, AggregateId : any) {
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + AuthToken
        },
        body: JSON.stringify(permitDetails)
    };
    return fetch(baseUrl + CompanyUrl + "/" + AggregateId  + UpdateNote  , requestOptions)
        .then(response => response)
}

//</editor-fold>
