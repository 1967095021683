import CadastralBlock    from "../components/blocks/CadastralBlock";
import EnergySystemBlock from "../components/blocks/EnergySystemBlock";
import PermitBlock       from "../components/blocks/PermitBlock";
import WellBlock         from "../components/blocks/WellBlock";

const SysteemGegevensOverview = () => {

    return (<>
        <EnergySystemBlock/>
        <CadastralBlock/>
        <WellBlock/>
        <PermitBlock/>
    </>)
}
export default SysteemGegevensOverview
