import CompanyBlock from "../components/blocks/CompanyBlock";
import NotitieBlock from "../components/blocks/NotitieBlock";
import SearchableCompanyBlock from "../components/blocks/SearchableCompanyBlock";
import SearchableEnergySystemBlock from "../components/blocks/SearchableEnergySystem";

const NotitiesOverview = () => {

    return (<>
        <NotitieBlock/>
    </>)
}
export default NotitiesOverview