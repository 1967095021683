import {
    Button,
    Typography
}                        from "@mui/material";
import {
    useEffect,
    useRef
}                        from "react";
import {
    Company,
    CompanyDetail,
    RegisterCompanyCommand,
    UpdateCompanyCommand
}                        from "../constants";
import {useGobesContext} from "../context/GobesContextProvider";
import {
    submitNewCompany,
    submitObject
} from "../util";

const CompanyForm = () =>
{
    const {
        companyDetails, setModalVisible,formData,
        setAlertText, setAlertVisible, setRefreshResource
    } = useGobesContext()
    const FormRef = useRef<any>()

    useEffect(() =>
    {
        if (FormRef.current !== undefined && companyDetails !== undefined)
        {
            FormRef.current.data = companyDetails.company
            if (formData !== undefined)
            {
                FormRef.current.data = {...formData.company}
            }
        }
    }, []);

    async function HandleSubmit(event: any)
    {

        //In case of a status that isnt 200, we most likely have received an error message.
        //So we display this as alert in the Modal
        if (event.status === 200)
        {
            //If everything went okay we can close it
            setModalVisible(false)
            setAlertVisible(false)
        }
        else
        {
            let response = await event.json()
            setAlertVisible(true)
            setAlertText(response.messages.reduceRight((acum: any, current: any) => acum.concat(current)))
        }

    }

    function onClickSubmit()
    {
        let Data: any = {}
        // @ts-ignore
        FormRef.current.getData().then((event: any) => Data = event)
        // @ts-ignore
        FormRef.current.submitForm().then(() =>
            {
                if (companyDetails === undefined)
                {
                    submitNewCompany(Data).then(async (event: any) =>
                    {
                        HandleSubmit(event)
                    })
                    setRefreshResource(Company)

                }
                else
                {
                    submitObject(Data, Data.id, UpdateCompanyCommand).then(async (event: any) =>
                    {
                        HandleSubmit(event)
                    })
                    setRefreshResource(CompanyDetail)

                }
            }
        )

    }


    return (<>

        <Typography id="modal-modal-title" variant="h6" component="h2">
            {companyDetails === undefined
             ? "Bedrijf toevoegen"
             : "Bedrijf bewerken"}
        </Typography>

        {/*// @ts-ignore*/}
        <ig-form ref={FormRef} form-id="Company"/>

        <Button variant="contained" onClick={onClickSubmit}>Submit</Button>
    </>)
}
export default CompanyForm