import {
    Button,
    Grid,
    Typography
}                        from "@mui/material";
import React, {
    useEffect,
    useRef
}                        from "react";
import {
    AddEnergySystem,
    EnergySystem,
    IGStyle,
}                        from "../../constants";
import {useGobesContext} from "../../context/GobesContextProvider";

const EnergySystemBlock = () =>
{
    const { setModalVisible, setModalAction,setEnergyDetails,setActivePage,setSubPage,setCompanyDetails
        , refreshResource, setRefreshResource,selectedCompanyRecord,setSelectedEnergyRecord,setFormData
    } = useGobesContext()

    const RMRef = useRef<any>()

    useEffect(() =>
    {
        RMRef.current?.addEventListener('recordSelected', (event: any) =>
        {
            setEnergyDetails(event.detail.record)
            setActivePage(2)
            setSubPage(0)
            setSelectedEnergyRecord(`energySystemId =="` + event.detail.record?.id + "\"")

        });

    }, [])


    useEffect(() =>
    {
        switch (refreshResource)
        {
            case EnergySystem:
                setTimeout(() => RMRef.current?.refresh(), 1000)
                break
        }
        setRefreshResource("")
    }, [refreshResource]);

    function AddObject()
    {
        setFormData(RMRef.current.data)
        setModalVisible(true)
        setModalAction(AddEnergySystem)

    }


    return (<>
        <Grid container justifyContent="center" direction={"column"}>
            <Grid container className={"rm-container"} direction={"row"}>
                <Grid container>
                    <Typography variant="h4" marginRight={"auto"}>
                        Energie Systemen
                    </Typography>
                </Grid>

                <Grid item xs={8} container alignItems={"stretch"} className={"containerspacing"}>
                    {/*// @ts-ignore*/}
                    <ig-resource-master lang="nl-NL" ref={RMRef} class={"ig-columns"} style={IGStyle} resource-master-id="EnergySystemRM" dsl-filter={selectedCompanyRecord}/>
                </Grid>

                <Grid container item className={"button-container"} xs={3} marginLeft={1} justifyContent={"flex-start"}>

                    <Grid item xs={4}>
                        <Grid container direction="column" item>
                            <Button variant={"contained"} onClick={AddObject}>Energiesysteem toevoegen</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    </>)
}

export default EnergySystemBlock