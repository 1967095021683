import CompanyBlock from "../components/blocks/CompanyBlock";
import SearchableCompanyBlock from "../components/blocks/SearchableCompanyBlock";
import SearchableEnergySystemBlock from "../components/blocks/SearchableEnergySystem";

const CompanyOverview = () => {

    return (<>
        <SearchableCompanyBlock/>
        <SearchableEnergySystemBlock/>
    </>)
}
export default CompanyOverview