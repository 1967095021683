import {
    Grid,
    Typography
}                               from "@mui/material";
import React, {
    useEffect,
    useRef
}                               from "react";
import {
    Company,
    IGStyle,
}                               from "../../constants";
import {useGobesContext}        from "../../context/GobesContextProvider";
import {getCompanyRecordSystem} from "../../util";

const SearchableEnergySystemBlock = () =>
{
    const {
        activePage, setActivePage, setEnergyDetails,
        setCompanyDetails, setSubPage, setAppStatus,
        setSelectedCompanyRecord, setTabStatus,
        refreshResource, setRefreshResource, setSelectedEnergyRecord
    } = useGobesContext()

    const RMRef = useRef<any>()

    useEffect(() =>
    {

        RMRef.current?.addEventListener('recordSelected', (event: any) =>
        {
            setEnergyDetails(event.detail.record)
            setActivePage(2)
            setSubPage(0)
            setSelectedEnergyRecord(`energySystemId =="` + event.detail.record?.id + "\"")

            getCompanyRecordSystem(event.detail.record.aggregateInstanceId).then(async (data: any) =>
                {
                    const Data = await data.json()
                    setCompanyDetails(Data)
                    setSelectedCompanyRecord(`aggregateInstanceId =="` + event.detail.record.aggregateInstanceId + "\"")
                }
            )
        });
        setTimeout(() =>
        {
            const box: any = document.getElementById("aggregateState0")
            if (box != null)
            {
                box.click()
                box.checked=true
                var event = new Event('change', { bubbles: true });
                box.dispatchEvent(event);
            }
        }, 1000)

    }, [])

    useEffect(() =>
    {
        switch (refreshResource)
        {
            case Company:
                setTimeout(() => RMRef.current?.refresh(), 1000)
                break
        }
        setRefreshResource("")
    }, [refreshResource]);

    return (<>
        <Grid container justifyContent="center" direction={"column"}>
            <Grid container className={"rm-container"} direction={"row"} display={activePage === 0
                                                                                  ? "flex"
                                                                                  : "none"}>
                <Grid container>
                    <Typography variant="h4" marginRight={"auto"}>
                        Energie Systemen
                    </Typography>
                </Grid>

                <Grid item xs={8} container alignItems={"stretch"} className={"containerspacing"}>
                    {/*// @ts-ignore*/}
                    <ig-resource-master lang="nl-NL" style={IGStyle} ref={RMRef} class={"ig-columns hide-filter-searchable-master"} searchable-resource-master-id="SearchableEnergySystemRM"/>
                </Grid>

                <Grid container item className={"button-container"} xs={3} marginLeft={1} justifyContent={"flex-start"}>

                    <Grid item xs={4}>
                        <Grid container direction="column" item>
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    </>)
}

export default SearchableEnergySystemBlock