import BoorprofielBlock from "../components/blocks/BoorprofielBlock";
import WellDetailBlock  from "../components/blocks/WellDetailBlock";

const BronBoorOverview = () =>
{

    return (<>
        <WellDetailBlock/>
        <BoorprofielBlock/>
    </>)
}
export default BronBoorOverview
