import {
    Button,
    Grid,
    Typography
}                      from "@mui/material";
import {
    useEffect,
    useRef,
    useState
}                      from "react";
import {
    AddCadastralInfo,
    AddContact,
    Cadastral,
    CadastralDetail,
    Contact,
    ContactDetail,
    DeactivateCadastralCommand,
    DeactivateContactCommand,
    IGStyle,
    UpdateCadastralInfo,
    UpdateContact
} from "../../constants";
import {useGobesContext} from "../../context/GobesContextProvider";
import {submitObject} from "../../util";

const CadastralBlock = () =>
{
    const {
        companyDetails,
        selectedCompanyRecord,selectedEnergyRecord,
        setFormData,
        setModalVisible,
        setModalAction,
        refreshResource, setRefreshResource, setAlertVisible, setAlertText
    } = useGobesContext()

    const RMRef = useRef<any>()
    const RDRef = useRef<any>()
    const [detailStatus, setDetailStatus] = useState<any>(false)
    const [detailRecord, setDetailRecord] = useState<any>("")
    useEffect(() =>
    {
        RMRef.current?.addEventListener('recordSelected', (event: any) =>
        {
            setFormData(event.detail.record)
            setDetailStatus(true)
            setDetailRecord(event.detail.recordId)
            //  setModalVisible(true)
            //  setModalAction(UpdateContact)
        })
    }, [])

    useEffect(() =>
    {
        switch (refreshResource)
        {
            case Cadastral:
                setTimeout(() => RMRef.current?.refresh(), 1000)
                break
            case CadastralDetail:
                setTimeout(() => RDRef.current?.refresh(), 1000)
                break
        }
        setRefreshResource("")
    }, [refreshResource]);

    function ResetDetailStatus()
    {
        setRefreshResource(Cadastral)
        setDetailStatus(false)
    }

    function AddObject()
    {
        setFormData(RMRef.current?.data)

        setModalVisible(true)
        setModalAction(AddCadastralInfo)
    }

    function EditObject() {
        setFormData(RDRef.current?.data)

        setModalVisible(true)
        setModalAction(UpdateCadastralInfo)
    }

    function DeleteObject() {
        submitObject({id: RDRef.current?.data.id},  companyDetails?.id, DeactivateCadastralCommand).then(async (event: any) =>
        {
            HandleSubmit(event)
        })

        setDetailStatus(false)
        setRefreshResource(Cadastral)
    }
    async function HandleSubmit(event: any)
    {
        //In case of a status that isnt 200, we most likely have received an error message.
        //So we display this as alert in the Modal
        if (event.status !== 200){
            const response = await event.json()
            setAlertVisible(true)
            setAlertText(response.messages.reduceRight((acum: any, current: any) => acum.concat(current)))
        }
    }

    return (<>
        <Grid container justifyContent="center" direction={"column"}>
            <Grid container className={"rm-container"} direction={"row"}>
                <Grid container>
                    <Typography variant="h4" marginRight={"auto"}>
                        Kadastrale Gegevens
                    </Typography>
                </Grid>

                <Grid item xs={8} container alignItems={"stretch"} className={"containerspacing"}>
                    <div style={{
                        display: detailStatus
                                 ? "contents"
                                 : "none"
                    }}>
                        <Button onClick={ResetDetailStatus}>Back</Button>
                        {/*// @ts-ignore*/}
                        <ig-resource-detail lang="nl-NL" ref={RDRef} class={"ig-detail-remove-2rows"} style={IGStyle} resource-detail-id="CadastralDetailRD" resource-id={detailRecord}/>
                    </div>
                    <div style={{
                        display: detailStatus
                                 ? "none"
                                 : "contents"
                    }}> {/*// @ts-ignore*/}
                        <ig-resource-master lang="nl-NL" ref={RMRef} style={IGStyle} class={"ig-columns"} resource-master-id="CadastralDetailRM" dsl-filter={selectedEnergyRecord}/>
                    </div>

                </Grid>

                <Grid container item className={"button-container"} xs={3} marginLeft={1} justifyContent={"flex-start"}>

                    <Grid container item xs={4}>
                        <Grid container direction="column" item>
                            <Button variant={"contained"} disabled={detailStatus} onClick={AddObject}>Kadastrale gegevens toevoegen</Button>
                            <Button variant={"contained"} disabled={!detailStatus} onClick={EditObject}>Kadastrale gegevens Bewerken</Button>
                            <Button variant={"contained"} disabled={!detailStatus} onClick={DeleteObject}>Kadastrale Gegevens Verwijderen</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    </>)
}

export default CadastralBlock