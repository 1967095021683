import {
    Button,
    Grid,
    Typography
}                      from "@mui/material";
import {
    useEffect,
    useRef,
    useState
}                      from "react";
import {
    AddBoorprofiel,
    AddContact,
    Boorprofiel,
    BoorprofielDetail,
    Contact,
    ContactDetail,
    DeactivateBoorprofielCommand,
    DeactivateContactCommand,
    IGStyle,
    UpdateBoorprofiel,
    UpdateContact
} from "../../constants";
import {useGobesContext} from "../../context/GobesContextProvider";
import {submitObject} from "../../util";

const BoorprofielBlock = () =>
{
    const {
        companyDetails,
        selectedCompanyRecord,
        setFormData,
        setModalVisible,
        setModalAction,
        refreshResource, setRefreshResource, setAlertVisible, setAlertText,selectedBronFilterRecord,
    } = useGobesContext()


    const RMRef = useRef<any>()
    const RDRef = useRef<any>()
    const [detailStatus, setDetailStatus] = useState<any>(false)
    const [detailRecord, setDetailRecord] = useState<any>("")
    useEffect(() =>
    {
        RMRef.current?.addEventListener('recordSelected', (event: any) =>
        {
            console.log(event)
            setFormData(event.detail.record)
            setDetailStatus(true)
            setDetailRecord(event.detail.recordId)
            //  setModalVisible(true)
            //  setModalAction(UpdateContact)
        })
    }, [])

    useEffect(() =>
    {
        switch (refreshResource)
        {
            case Boorprofiel:
                setTimeout(() => RMRef.current?.refresh(), 1000)
                break
            case BoorprofielDetail:
                setTimeout(() => RDRef.current?.refresh(), 1000)
                break
        }
        setRefreshResource("")
    }, [refreshResource]);

    function ResetDetailStatus()
    {
        setRefreshResource(Boorprofiel)
        setDetailStatus(false)
    }

    function AddObject()
    {
        setFormData(RMRef.current?.data)

        setModalVisible(true)
        setModalAction(AddBoorprofiel)
    }

    function UpdateObject() {
        setFormData(RDRef.current?.data)

        setModalVisible(true)
        setModalAction(UpdateBoorprofiel)
    }

    function DeleteObject() {
        submitObject({id: RDRef.current?.data.id},  companyDetails?.id, DeactivateBoorprofielCommand).then(async (event: any) =>
        {
            HandleSubmit(event)
        })

        setDetailStatus(false)
        setRefreshResource(Boorprofiel)
    }
    async function HandleSubmit(event: any)
    {
        //In case of a status that isnt 200, we most likely have received an error message.
        //So we display this as alert in the Modal
        if (event.status !== 200){
            const response = await event.json()
            setAlertVisible(true)
            setAlertText(response.messages.reduceRight((acum: any, current: any) => acum.concat(current)))
        }
    }

    return (<>
        <Grid container justifyContent="center" direction={"column"}>
            <Grid container className={"rm-container"} direction={"row"}>
                <Grid container>
                    <Typography variant="h4" marginRight={"auto"}>
                        BoorProfiel
                    </Typography>
                </Grid>

                <Grid item xs={8} container alignItems={"stretch"} className={"containerspacing"}>
                    <div style={{
                        display: detailStatus
                                 ? "contents"
                                 : "none"
                    }}>
                        <Button onClick={ResetDetailStatus}>Back</Button>
                        {/*// @ts-ignore*/}
                        <ig-resource-detail lang="nl-NL" ref={RDRef} class={"ig-detail-remove-2rows"} style={IGStyle} resource-detail-id="BoorprofielRD" resource-id={detailRecord}/>
                    </div>
                    <div style={{
                        display: detailStatus
                                 ? "none"
                                 : "contents"
                    }}> {/*// @ts-ignore*/}
                        <ig-resource-master lang="nl-NL" ref={RMRef} style={IGStyle} class={"ig-columns"} resource-master-id="BoorprofielRM" dsl-filter={selectedBronFilterRecord}/>
                    </div>

                </Grid>

                <Grid container item className={"button-container"} xs={3} marginLeft={1} justifyContent={"flex-start"}>

                    <Grid container item xs={4}>
                        <Grid container direction="column" item>
                            <Button variant={"contained"} disabled={detailStatus} onClick={AddObject}>Boorprofiel Toevoegen</Button>
                            <Button variant={"contained"} disabled={!detailStatus} onClick={UpdateObject}>Boorprofiel Bewerken</Button>
                            <Button variant={"contained"} disabled={!detailStatus} onClick={DeleteObject}>Boorprofiel Verwijderen</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    </>)
}

export default BoorprofielBlock