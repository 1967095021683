import CompanyBlock              from "../components/blocks/CompanyBlock";
import ContactBlock              from "../components/blocks/ContactBlock";
import FilteredEnergySystemBlock from "../components/blocks/FilteredEnergySystemBlock";

const CompanyDetailOverview = () => {

    return (<>
        <CompanyBlock/>
        <ContactBlock/>
        <FilteredEnergySystemBlock/>
    </>)
}
export default CompanyDetailOverview
