import {
    applyPolyfills,
    defineCustomElements as defineIgWebComponents
}                             from "informationgrid-webcomponents/dist/loader/index";
import "./resources/style.scss"
import React                  from 'react';
import './App.css';
import ManagementPage         from "./components/ManagementPage";
import {GobesContextProvider} from "./context/GobesContextProvider";
import CssBaseline from '@mui/material/CssBaseline';
import MainPage               from "./pages/MainPage";

applyPolyfills().then(() => defineIgWebComponents(window));

function App()
{
    return (
        <div className="App">
            <GobesContextProvider>
                <MainPage/>
                {/*<ManagementPage/>*/}
            </GobesContextProvider>
        </div>
    );
}

export default App;
